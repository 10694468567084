import { Numeric } from '../../types/general';

type IDRoute = `${string}/:id${string}`;

export function constructIdRoute(route: IDRoute, id: Numeric | undefined) {
  return route.replace(':id', String(id));
}

export namespace RoutePaths {
  export const Welcome = '/welcome';
  export const Dashboard = '/dashboard';
  export const Clients = '/clients';
  export const Settings = '/settings';

  export const CourierLiabilities = '/logistics/courier-liabilities';
  export const AddCourierLiability = '/logistics/courier-liabilities/add';
  export const ViewCourierLiability = '/logistics/courier-liabilities/:id/view';
  export const EditCourierLiability = '/logistics/courier-liabilities/:id/edit';
  export const DeleteCourierLiability =
    '/logistics/courier-liabilities/:id/delete';

  export const Prices = '/billing/prices';
  export const AddPrice = '/billing/prices/add';
  export const EditPrice = '/billing/prices/:id/edit';
  export const DeletePrice = '/billing/prices/:id/delete';

  export const PriceLists = '/billing/price-lists';
  export const AddPriceList = '/billing/price-lists/add';
  export const EditPriceList = '/billing/price-lists/:id/edit';
  export const DeletePriceList = '/billing/price-lists/:id/delete';

  export const Logs = '/logs';

  export const Tariffs = '/billing/tariffs';
  export const AddTariff = '/billing/tariffs/:id/add';
  export const EditTariff = '/billing/tariffs/:id/edit';
  export const DeleteTariff = '/billing/tariffs/:id/delete';

  export const Vehicles = '/management/vehicles';
  export const ViewVehicle = '/management/vehicles/:id/view';
  export const AddVehicle = '/management/vehicles/add';
  export const EditVehicle = '/management/vehicles/:id/edit';
  export const DeleteVehicle = '/management/vehicles/:id/delete';

  export const Employees = '/management/employees';
  export const DeviceManagement = '/management/device-management';

  export const SmartPosTerminals = '/management/smart-pos-terminals';
  export const AddSmartPosTerminals = '/management/smart-pos-terminals/:id/add';
  export const EditSmartPosTerminals =
    '/management/smart-pos-terminals/:id/edit';
  export const DeleteSmartPosTerminals =
    '/management/smart-pos-terminals/:id/delete';

  export const Regions = '/management/regions';

  export const CouriersCash = '/logistics/couriers-cash';

  export const OAuthClients = '/management/oauth-clients';
  export const ViewOAuthClient = '/management/oauth-clients/:id/view';
  export const AddOAuthClient = '/management/oauth-clients/add';
  export const EditOAuthClient = '/management/oauth-clients/:id/edit';
  export const DeleteOAuthClient = '/management/oauth-clients/:id/delete';
  export const EmailOAuthClient = '/management/oauth-clients/:id/email';

  export const BulkOrders = '/orders/batch';
  export const ListTypes = '/orders/batch-order-templates';

  export const OrdersHistory = '/orders/:id/history';
  export const PaymentOrderHistory = '/orders/:id/history/payment';
  export const DeliveryOrderHistory = '/orders/:id/history/delivery';

  export const Orders = '/orders/active';
  export const ViewOrder = '/orders/active/:id/view';
  export const AddOrder = '/orders/active/add';
  export const EditOrder = '/orders/active/:id/edit';
  export const DeleteOrder = '/orders/active/:id/delete';
  export const CancelOrder = '/orders/active/:id/cancel';
  export const StatusOrder = '/orders/active/:id/status';
  export const GroupOrder = '/orders/active/group';

  export const DeletedOrders = '/orders/deleted';
  export const ViewDeletedOrder = '/orders/deleted/:id/view';

  export const ShipmentReconciliations =
    '/finances/reconciliations/:id/shipments';
  export const DeliveredShipmentReconciliations =
    '/finances/reconciliations/:id/shipments/delivered';
  export const PickedUpShipmentReconciliations =
    '/finances/reconciliations/:id/shipments/picked-up';

  export const CourierReconciliations = '/finances/reconciliations';
  export const AddCourierReconciliation = '/finances/reconciliations/create';

  export const CourierMap = '/monitoring/courier-map';
  export const ReceptionAndDeliveryMap =
    '/monitoring/reception-and-delivery-map';
  export const TrackShipmentsByLocation =
    '/monitoring/track-shipments-by-location';
  export const ViewTrackedShipmentByLocation =
    '/monitoring/track-shipments-by-location/:id/view';
  export const EditTrackedShipmentByLocation =
    '/monitoring/track-shipments-by-location/:id/edit';

  export const HubsReports = '/monitoring/hubs-reports';
  export const HubReportsView = '/monitoring/hubs-reports/:id/view';

  export const WarehousesList = '/management/warehouses-list';

  export const CourierPickupAssignment =
    '/logistics/assignment/courier-pickup-assignment';

  export const CourierShipmentReceptions = '/logistics/reception/courier';
  export const ViewCourierShipmentReception =
    '/logistics/reception/courier/view/:id';

  export const CourierShipmentAssignments = '/logistics/assignment/courier';
  export const ViewCourierShipmentAssignment =
    '/logistics/assignment/courier/view/:id';

  export const HubShipmentReceptions = '/logistics/reception/hub';
  export const ViewHubShipmentReception = '/logistics/reception/hub/view/:id';

  export const HubShipmentDistributions = '/logistics/assignment/hub';
  export const ViewHubShipmentDistribution =
    '/logistics/assignment/hub/view/:id';

  export const TransfersCourierToCourier =
    '/logistics/reception/transfer-to-courier';
  export const ViewTransferCourierToCourier =
    '/logistics/reception/transfer-to-courier/view/:id';

  export const CustomerShipmentReception = '/dev/logistics/reception/customer';
  export const AddCustomerShipmentReception =
    '/dev/logistics/reception/customer/new';
  export const AddSingleOrderCustomerShipmentReception =
    '/dev/logistics/reception/customer/add-single-order';
  export const AddBatchOrderCustomerShipmentReception =
    '/dev/logistics/reception/customer/add-batch-order';

  export const HandoverToCustomer =
    '/dev/logistics/assignment/handover-to-customer';

  export const Invoices = '/finances/invoices/preview';

  export const AddInvoice = '/finances/invoices/create';

  export const AddWarrant = '/finances/cod/create-warrants';

  export const Warrants = '/finances/cod/warrants';
  export const ViewWarrant = '/finances/cod/warrants/:id/view';
  export const EmailWarrant = '/finances/cod/warrants/:id/email';

  export const CustomerOrders = '/reports/customer-orders';
  export const ViewCustomerOrder = '/reports/customer-orders/:id';

  export const RoutesPage = '/dev/management/routes';
  export const ViewRoute = '/dev/management/routes/:id/view';
  export const EditRoute = '/dev/management/routes/:id/edit';

  export const Cashdesk = '/dev/reports/cashdesk';

  export const CustomerEarnings = '/dev/reports/customer-earnings';

  export const CourierCompensation = '/dev/courier-compensation';

  export const Login = '/login';
  export const Logout = '/logout';
  export const Profile = '/profile';
  export const ForgotPassword = '/forgotpassword';

  export const Error401 = '/401';
  export const Error403 = '/403';
  export const Error404 = '/404';
  export const Error500 = '/500';
}
