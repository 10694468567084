import { faCube } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableRowClickEventParams } from 'primereact/datatable';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';

import ToastContext from '../../../context/ToastContext';
import { FileTypes } from '../../../enums/files';
import useAxiosHook from '../../../hooks/useAxiosHook';
import { useEndpointGuard } from '../../../hooks/useEndpointGuard';
import useMediaQuery from '../../../hooks/useMediaQuery';
import usePageTitle from '../../../hooks/usePageTitle';
import usePageTitleToggler from '../../../hooks/usePageTitleToggler';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { WithPaginationAndSummary } from '../../../types/api';
import { EntityIdRouteParams } from '../../../types/routing';
import * as ordersGuards from '../../../utils/constants/auth/orders';
import {
  RoutePaths,
  constructIdRoute,
} from '../../../utils/constants/routePaths';
import {
  downloadAddressBook,
  downloadFile,
  downloadSticker,
  getFileName,
} from '../../../utils/helpers/files';
import { currencyFormat } from '../../../utils/helpers/formatting';
import { noop } from '../../../utils/helpers/functions';
import { queryString } from '../../../utils/helpers/http';
import { httpQueryObject } from '../../../utils/helpers/misc';
import { getSearchQueryParam } from '../../../utils/helpers/searchQuery';
import Table from '../../DataTable/Table/Table';
import Flex from '../../layout/flex/Flex';
import MainContent from '../../layout/flex/MainContent';
import Filters from '../Components/Filters/Filters';
import HeaderPages from '../Components/HeaderPages/HeaderPages';
import CancelOrderDialog from './Dialogs/Cancel/CancelOrderDialog';
import CreateEditDialog from './Dialogs/CreateEdit/CreateEditDialog';
import { GroupOrderDialog } from './Dialogs/CreateEdit/GroupOrderDialog';
import DeleteDialog from './Dialogs/Delete/DeleteDialog';
import StatusTrackingDialog from './Dialogs/StatusTracking/StatusTrackingDialog';
import ViewActiveOrderDialog from './Dialogs/View/ViewActiveOrderDialog';
import {
  Order,
  SingleOrder,
  additionalColumnProperties,
  generateGroupActions,
  getColumnHeadersMap,
  getStatuses,
  hoursToNow,
  semaphoreStatusesValues,
  tableStorageKey,
} from './Orders.functions';
import useTableFilters from './useTableFilters';

function Orders(): JSX.Element {
  const { id } = useParams<EntityIdRouteParams>();
  const { t } = useTranslation();
  usePageTitle(t('Orders'));
  const history = useHistory();
  const location = useLocation();
  const [doubleClick, setIsDoubleClick] = useState(false);
  const [action, setAction] = useState<string>('');
  const readGuard = useEndpointGuard(ordersGuards.readOrder);
  const createGuard = useEndpointGuard(ordersGuards.createOrder);
  const editGuard = useEndpointGuard(ordersGuards.editOrder);
  const statusTrackingGuard = useEndpointGuard(ordersGuards.statusTracking);
  const historyGuard = useEndpointGuard(ordersGuards.orderHistory);
  const cancelGuard = useEndpointGuard(ordersGuards.cancelOrder);
  const deleteGuard = useEndpointGuard(ordersGuards.deleteOrder);
  const exportGuard = useEndpointGuard(ordersGuards.exportOrders);
  const isSmallScreen = useMediaQuery('(max-width: 1000px)');
  const [caller, setCaller] =
    useState<'group-actions' | 'context-menu'>('context-menu');
  const { bottomRightToastRef } = useContext(ToastContext);
  const isSemaphoreFilterOn =
    Boolean(getSearchQueryParam(location.search, 'semaphore')) ||
    _.isEqual(getStatuses(location), semaphoreStatusesValues);

  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selectionMultiple,
    setSortField,
    setSortOrder,
    setSelectionMultiple,
    contextMenuSelection,
    setContextMenuSelection,
  } = useTableState<Order>(tableStorageKey);

  const { headerFiltersCount, filters, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage!, limit);

  const canLoadData = httpFiltersObj.date_from && httpFiltersObj.date_to;

  const { data, isLoading, reload, error } = useAxiosHook<
    WithPaginationAndSummary<Order[], { redemption: string }>
  >(
    {
      url: '/orders' + queryString(httpQueryObject(httpFiltersObj)),
    },
    {
      skipWhen: !canLoadData,
    }
  );

  function customDataModifier(data: Order[]): Order[] {
    if (!isSemaphoreFilterOn) {
      return data;
    }

    return data
      ?.map((order: Order) => {
        return {
          ...order,
          time_from_pickup: hoursToNow(order.datum_priem ?? undefined),
        };
      })
      .sort((a, b) => b.time_from_pickup - a.time_from_pickup);
  }

  const {
    show: showCreateDialog,
    hide: hideCreateDialog,
    isVisible: isCreateDialogVisible,
  } = useRouteDialog(RoutePaths.Orders, RoutePaths.AddOrder);

  const {
    show: showEditDialog,
    hide: hideEditDialog,
    isVisible: isEditDialogVisible,
  } = useRouteDialog(
    RoutePaths.Orders,
    constructIdRoute(RoutePaths.EditOrder, id ?? contextMenuSelection?.id)
  );

  const {
    show: showGroupDialog,
    hide: hideGroupDialog,
    isVisible: isGroupDialogVisible,
  } = useRouteDialog(RoutePaths.Orders, RoutePaths.GroupOrder);

  const {
    show: showStatusTrackingDialog,
    hide: hideStatusTrackingDialog,
    isVisible: isStatusTrackingDialogVisible,
  } = useRouteDialog(
    RoutePaths.Orders,
    constructIdRoute(RoutePaths.StatusOrder, id ?? contextMenuSelection?.id)
  );

  const {
    show: showViewActiveOrderDialog,
    hide: hideViewActiveOrderDialog,
    isVisible: isViewActiveOrderDialogVisible,
  } = useRouteDialog(
    RoutePaths.Orders,
    constructIdRoute(RoutePaths.ViewOrder, id ?? contextMenuSelection?.id)
  );

  const {
    show: showDeleteDialog,
    hide: hideDeleteDialog,
    isVisible: isDeleteDialogVisible,
  } = useRouteDialog(
    RoutePaths.Orders,
    constructIdRoute(RoutePaths.DeleteOrder, id ?? contextMenuSelection?.id)
  );

  const {
    show: showCancelDialog,
    hide: hideCancelDialog,
    isVisible: isCancelDialogVisible,
  } = useRouteDialog(
    RoutePaths.Orders,
    constructIdRoute(RoutePaths.CancelOrder, id ?? contextMenuSelection?.id)
  );

  const handleCMHistoryClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    history.push(
      constructIdRoute(RoutePaths.OrdersHistory, contextMenuSelection.id)
    );
  }, [contextMenuSelection, history]);

  const handleCMPrintAddressDocClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    downloadAddressBook(
      { shipment_ids: [contextMenuSelection.id] },
      getFileName(t('Orders'), [
        contextMenuSelection.klient_od_ime ?? '',
        contextMenuSelection.klient_do_ime ?? '',
        contextMenuSelection.seriski_broj,
        t('AddressDocument'),
      ]),
      FileTypes.PDF,
      bottomRightToastRef
    );
  }, [contextMenuSelection, t, bottomRightToastRef]);

  function handleCMPrintMultipleAdressDocClick() {
    if (!selectionMultiple) {
      return;
    }

    downloadAddressBook(
      {
        shipment_ids: selectionMultiple.map(function getId(selection) {
          return selection.id;
        }),
      },
      getFileName(t('Orders'), t('AddressDocument')),
      FileTypes.PDF,
      bottomRightToastRef
    );
  }

  const handleCMPrintStickerClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }

    const fileName = getFileName(t('Orders'), [
      contextMenuSelection.klient_od_ime ?? '',
      contextMenuSelection.klient_do_ime ?? '',
      contextMenuSelection.seriski_broj,
      t('Sticker'),
    ]);

    downloadSticker(
      {
        id: contextMenuSelection.id,
      },
      fileName,
      FileTypes.PDF,
      bottomRightToastRef
    );
  }, [contextMenuSelection, t, bottomRightToastRef]);

  function handleCMPrintMultipleStickerClick() {
    if (!selectionMultiple) {
      return;
    }

    downloadSticker(
      {
        shipment_ids: selectionMultiple.map(function getId(selection) {
          return selection.id;
        }),
      },
      getFileName(t('Orders'), t('Stickers'), true),
      FileTypes.PDF,
      bottomRightToastRef
    );
  }

  function handleCreateEditReload() {
    reload();
  }

  const handleCMDeleteClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    showDeleteDialog();
  }, [contextMenuSelection, showDeleteDialog]);

  const handleCMDCancelClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    showCancelDialog();
  }, [contextMenuSelection, showCancelDialog]);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(
      page,
      limit,
      'orders_activeOrders',
      columnHeadersMap,
      columnHeadersMap,
      (c) =>
        additionalColumnProperties(
          t,
          c as keyof typeof columnHeadersMap,
          setContextMenuSelection,
          setAction,
          setCaller,
          readGuard,
          editGuard,
          statusTrackingGuard,
          historyGuard,
          deleteGuard,
          cancelGuard
        )
    );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      // <Column // need to be discussed with Kire
      //   key="time_from_pickup"
      //   header={t('Semaphore')}
      //   field="time_from_pickup"
      //   {...additionalColumnProperties(t, 'time_from_pickup')}
      // />,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction,
          setCaller,
          readGuard,
          editGuard,
          statusTrackingGuard,
          historyGuard,
          deleteGuard,
          cancelGuard
        )}
      />,
    ],
    [
      columns,
      // isSemaphoreFilterOn,
      t,
      setContextMenuSelection,
      setAction,
      setCaller,
      readGuard,
      editGuard,
      statusTrackingGuard,
      historyGuard,
      deleteGuard,
      cancelGuard,
    ]
  );

  usePageTitleToggler(
    !contextMenuSelection
      ? t('Loading...')
      : selectionMultiple?.length > 1
      ? t('Cancel multiple orders')
      : t('Cancel order {{serialNo}}', {
          serialNo: contextMenuSelection?.seriski_broj ?? '',
        }),
    t('Orders'),
    isCancelDialogVisible
  );

  const { data: activeOrderData, isLoading: isActiveOrderDataLoading } =
    useAxiosHook<SingleOrder>(
      {
        url: `/orders/${id ?? contextMenuSelection?.id}`,
      },
      {
        skipWhen:
          (!id && !contextMenuSelection?.id) ||
          (!isCreateDialogVisible &&
            !isEditDialogVisible &&
            !isGroupDialogVisible &&
            !isViewActiveOrderDialogVisible &&
            !isStatusTrackingDialogVisible) ||
          !readGuard,
      }
    );

  usePageTitleToggler(
    isActiveOrderDataLoading ? t('Loading...') : t('Create order'),
    t('Orders'),
    isCreateDialogVisible
  );

  usePageTitleToggler(
    isActiveOrderDataLoading
      ? t('Loading...')
      : t('{{serialNo}} edit', {
          serialNo: activeOrderData?.seriski_broj ?? '',
        }),
    t('Orders'),
    isEditDialogVisible
  );

  usePageTitleToggler(
    isActiveOrderDataLoading
      ? t('Loading...')
      : t('{{serialNo}} status tracking', {
          serialNo: activeOrderData?.seriski_broj ?? '',
        }),
    t('Orders'),
    isStatusTrackingDialogVisible
  );

  usePageTitleToggler(
    isActiveOrderDataLoading
      ? t('Loading...')
      : t('{{serialNo}} preview', {
          serialNo: activeOrderData?.seriski_broj ?? '',
        }),
    t('Orders'),
    isViewActiveOrderDialogVisible
  );

  usePageTitleToggler(
    isActiveOrderDataLoading
      ? t('Loading...')
      : t('Delete order {{serialNo}}', {
          serialNo: activeOrderData?.seriski_broj ?? '',
        }),
    t('Orders'),
    isDeleteDialogVisible
  );

  function handleCMViewDoubleClick(e: DataTableRowClickEventParams) {
    if (!e.data) {
      return;
    }

    setContextMenuSelection(e.data);
    setIsDoubleClick(true);
  }

  useEffect(() => {
    if (doubleClick && contextMenuSelection) {
      showViewActiveOrderDialog();
      setIsDoubleClick(false);
    }
  }, [contextMenuSelection, doubleClick, showViewActiveOrderDialog]);

  function handleExportExcel() {
    downloadFile(
      `/orders/export/excel` +
        queryString(_.omit(httpFiltersObj, ['page', 'limit']) as any),
      getFileName(t('Orders'), undefined, true),
      FileTypes.XLSX,
      bottomRightToastRef
    );
  }

  function handleExportCSV() {
    downloadFile(
      '/orders/export/csv' +
        queryString(_.omit(httpFiltersObj, ['page', 'limit']) as any),
      getFileName(t('Orders'), undefined, true),
      FileTypes.CSV,
      bottomRightToastRef
    );
  }

  function handleCreateEditHide() {
    hideCreateDialog();
    hideEditDialog();
    hideGroupDialog();
  }

  function handleFromViewToEdit() {
    showEditDialog();
  }

  useEffect(() => {
    if (!readGuard) {
      hideViewActiveOrderDialog();
    }
  }, [hideViewActiveOrderDialog, readGuard]);

  useEffect(() => {
    if (!editGuard) {
      hideEditDialog();
    }
  }, [editGuard, hideEditDialog]);

  useEffect(() => {
    if (!createGuard) {
      hideCreateDialog();
      hideGroupDialog();
    }
  }, [createGuard, hideCreateDialog, hideGroupDialog]);

  useEffect(() => {
    if (!statusTrackingGuard) {
      hideStatusTrackingDialog();
    }
  }, [hideStatusTrackingDialog, statusTrackingGuard]);

  useEffect(() => {
    if (!deleteGuard) {
      hideDeleteDialog();
    }
  }, [deleteGuard, hideDeleteDialog]);

  useEffect(() => {
    if (!cancelGuard) {
      hideCancelDialog();
    }
  }, [cancelGuard, hideCancelDialog]);

  useEffect(() => {
    if (isSmallScreen) {
      hideGroupDialog();
    }
  }, [hideCreateDialog, hideGroupDialog, isSmallScreen]);

  useEffect(() => {
    if (action && contextMenuSelection) {
      setCaller('context-menu');

      if (action === 'view-details') {
        showViewActiveOrderDialog();
      }
      if (action === 'edit') {
        showEditDialog();
      }
      if (action === 'status-tracking') {
        showStatusTrackingDialog();
      }
      if (action === 'history') {
        handleCMHistoryClick();
      }
      if (action === 'print-address-document') {
        handleCMPrintAddressDocClick();
      }
      if (action === 'print-sticker') {
        handleCMPrintStickerClick();
      }
      if (action === 'delete-order') {
        showDeleteDialog();
      }
      if (action === 'cancel-order') {
        showCancelDialog();
      }
      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    setAction,
    showViewActiveOrderDialog,
    showEditDialog,
    showStatusTrackingDialog,
    handleCMPrintStickerClick,
    handleCMPrintAddressDocClick,
    showDeleteDialog,
    handleCMHistoryClick,
    showCancelDialog,
  ]);

  const paginatorLeft = (
    <span>
      {t('Total redemption')}:{' '}
      <b>
        {currencyFormat(data?.summary?.redemption ?? '', {
          showCurrency: true,
        })}
      </b>
    </span>
  );

  return (
    <div className="page orders-page">
      <HeaderPages
        title={t('Orders')}
        subtitle={t('View and manage orders')}
        icon={faCube}
      >
        {createGuard && (
          <div>
            <Button
              label={t('Create order')}
              icon="fas fa-plus"
              className="main-btn"
              onClick={showCreateDialog}
              data-cy="create-btn"
            />

            {!isSmallScreen && (
              <Button
                label={t('Group order')}
                icon="fas fa-plus"
                className="main-btn p-button-outlined"
                onClick={showGroupDialog}
                data-cy="group-order-btn"
              />
            )}
          </div>
        )}
      </HeaderPages>

      <CreateEditDialog
        visible={isCreateDialogVisible || isEditDialogVisible}
        onHide={handleCreateEditHide}
        data={activeOrderData}
        isLoading={isActiveOrderDataLoading}
        isEditDialog={isEditDialogVisible}
        reload={handleCreateEditReload}
        isSmallScreen={isSmallScreen}
        setOrderData={() => {}}
      />

      <GroupOrderDialog
        reload={reload}
        onHide={handleCreateEditHide}
        visible={isGroupDialogVisible && !isSmallScreen}
        data={activeOrderData}
        isSmallScreen={isSmallScreen}
      />

      <ViewActiveOrderDialog
        visible={isViewActiveOrderDialogVisible}
        data={activeOrderData}
        isLoading={isActiveOrderDataLoading}
        onHide={hideViewActiveOrderDialog}
        {...(editGuard ? { onEdit: handleFromViewToEdit } : {})}
      />

      <StatusTrackingDialog
        visible={isStatusTrackingDialogVisible}
        data={activeOrderData}
        isLoading={isActiveOrderDataLoading}
        onHide={hideStatusTrackingDialog}
      />

      <CancelOrderDialog
        visible={isCancelDialogVisible}
        data={
          caller === 'context-menu'
            ? contextMenuSelection
              ? [contextMenuSelection]
              : []
            : selectionMultiple
        }
        onHide={() => hideCancelDialog()}
        reloadOrders={reload}
        setSelectionMultiple={setSelectionMultiple}
      />

      <DeleteDialog
        visible={isDeleteDialogVisible}
        data={
          caller === 'context-menu'
            ? contextMenuSelection
              ? [contextMenuSelection]
              : []
            : selectionMultiple
        }
        onHide={() => hideDeleteDialog()}
        reloadOrders={reload}
        setSelectionMultiple={setSelectionMultiple}
      />

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
        />
        <MainContent>
          <Table
            tableRef={tableRef}
            columns={finalColumns}
            data={data}
            isLoading={isLoading}
            reload={reload}
            isReloadDisabled={!canLoadData}
            hasError={!!error}
            headerTitle=""
            setPage={setPage}
            setLimit={setLimit}
            sortField={sortField}
            rows={limit}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            setSelection={setSelectionMultiple}
            sortOrder={sortOrder}
            selection={selectionMultiple}
            customDataModifier={customDataModifier as any}
            onRowDoubleClick={readGuard ? handleCMViewDoubleClick : noop}
            storageString={tableStorageKey}
            selectionMode="multiple"
            clearSelectionObj={httpFiltersObj}
            selectionPageOnly
            rebuildTooltip
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            paginatorLeft={paginatorLeft}
            columnOptions={columnOptions}
            exportToCSVButton={exportGuard}
            onExportToCSVButtonClick={exportGuard ? handleExportCSV : () => {}}
            exportToExcelButton={exportGuard}
            onExportToExcelButtonClick={
              exportGuard ? handleExportExcel : () => {}
            }
            minGroupSelection={2}
            groupActionsModel={generateGroupActions(
              t,
              handleCMPrintMultipleAdressDocClick,
              handleCMPrintMultipleStickerClick,
              handleCMDCancelClick,
              handleCMDeleteClick,
              setCaller
            )}
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}
export default Orders;
