import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { SmartPOSTerminals } from '../../../../../types/api/smartPosTerminals';
import { Numeric } from '../../../../../types/general';
import { LabelValue } from '../../../../../types/options';
import { yupRequiredField } from '../../../../../utils/helpers/yup';

export function smartPOSTerminalsStatuses(t: TFunction): LabelValue[] {
  return [
    { label: t('Active'), value: '1' },
    { label: t('Inactive'), value: '2' },
    { label: t('Blocked'), value: '2' },
  ];
}

export type FormFields = {
  username: string;
  activation_code: Numeric;
  pin: Numeric;
  status_id: Numeric;
  terminal_id: string;
  device_id: Numeric;
};

export function getDefaultValues(
  isEditDialog: boolean,
  data: SmartPOSTerminals | undefined
): FormFields {
  return {
    username: isEditDialog ? data?.username ?? '' : '',
    activation_code: isEditDialog ? data?.activationCode ?? '' : '',
    pin: isEditDialog ? data?.pin ?? 0 : 0,
    status_id: isEditDialog ? data?.statusId ?? '1' : '1',
    terminal_id: isEditDialog ? data?.terminalId ?? '' : '',
    device_id: isEditDialog ? data?.deviceId ?? 0 : 0,
  };
}

export function toApiData(values: FormFields): Partial<FormFields> {
  return values;
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    username: Yup.string().required(yupRequiredField(t, t('Name'))),
    activationCode: Yup.string().required(
      yupRequiredField(t, t('Activation Code'))
    ),
    pin: Yup.string().required(yupRequiredField(t, t('Pin'))),
    statusId: Yup.string().required(yupRequiredField(t, t('Status'))),
    terminalId: Yup.string().required(yupRequiredField(t, t('Terminal ID'))),
    deviceId: Yup.string().required(yupRequiredField(t, t('Device ID'))),
  });
}
