import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { yupRequiredField } from '../../../../../utils/helpers/yup';

export type FormFields = Record<keyof Omit<any, 'id'>, string>;

export function getInitialValues(isEditDialog: boolean, data: any | undefined) {
  // const fields: Record<keyof FormFields, string> = {
  //   ime: '',
  //   kod: '',
  //   opis: '',
  //   izraz: '',
  //   nsot: '0',
  //   ndot: '0',
  //   nmxt: '0',
  //   aktivna: '1',
  // };
  // return Object.fromEntries(
  //   Object.keys(fields).map((f) => [
  //     f,
  //     isEditDialog
  //       ? data?.[f as keyof FormFields] || fields[f as keyof FormFields]
  //       : fields[f as keyof FormFields],
  //   ])
  // ) as FormFields;
  console.log(isEditDialog, data);
}

export function toApiData(values: FormFields): Partial<FormFields> {
  return values;
}

export function getValidationSchema(t: TFunction) {
  return Yup.object().shape({
    ime: Yup.string().required(yupRequiredField(t, t('Tariff'))),
    kod: Yup.string().required(yupRequiredField(t, t('Display name'))),
    opis: Yup.string().required(yupRequiredField(t, t('Description'))),
    izraz: Yup.string().required(yupRequiredField(t, t('Expression'))),
    nsot: Yup.string().required(
      yupRequiredField(t, t('Naplati ja samo ovaa tarifa'))
    ),
    ndot: Yup.string().required(
      yupRequiredField(t, t('Naplati do ovaa tarifa'))
    ),
    nmxt: Yup.string().required(
      yupRequiredField(t, t('Naplati maksimalna tarifa'))
    ),
    aktivna: Yup.string().required(yupRequiredField(t, t('Active'))),
  });
}
