import { Form, Formik, FormikProps } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import useMediaQuery from '../../../../../hooks/useMediaQuery';
import usePrevious from '../../../../../hooks/usePrevious';
import { EntityIdRouteParams } from '../../../../../types/routing';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';
import DialogSpinner from '../../../../Dialogs/DialogSpinner/DialogSpinner';
import DialogContainer from '../../../Orders/Dialogs/CreateEdit/DialogContainer';
import {
  FormFields,
  getInitialValues,
  getValidationSchema,
  toApiData,
} from './AddEditDialog.functions';

type Props = {
  isEditDialog: boolean;
  isShown: boolean;
  onHide: () => void;
};

function AddEditDialog({ isEditDialog, isShown, onHide }: Props): JSX.Element {
  const { t } = useTranslation();

  const { id } = useParams<EntityIdRouteParams>();

  const { toastRef } = useContext(ToastContext);

  const formRef = useRef<FormikProps<FormFields>>(null);

  const {
    data: tariffData,
    error: tariffError,
    isLoading: isTariffLoading,
  } = useAxiosHook<any>(`/replace/me/${id}`, {
    skipWhen: !isShown || !isEditDialog || true, // !!!
  });

  const prevTariffError = usePrevious(tariffError);

  useEffect(() => {
    if (!tariffError || tariffError === prevTariffError) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while reading tariff data.')
      );
    }

    onHide();
  }, [onHide, prevTariffError, tariffError, t, toastRef]);

  const {
    data: addData,
    error: addError,
    isLoading: isAddLoading,
    reload: addReload,
  } = useAxiosHook(
    'replace/me', // !!!
    {
      skipWhen: true,
    }
  );

  const prevAddData = usePrevious(addData);
  const prevAddError = usePrevious(addError);

  useEffect(() => {
    if (!addData || addData === prevAddData) {
      return;
    }

    if (toastRef?.current) {
      successToast(
        toastRef,
        t('Success'),
        t('Tariff {{name}} has been added successfully.', {
          name: formRef.current?.values.ime ?? '',
        })
      );
    }

    onHide();
  }, [addData, onHide, prevAddData, t, toastRef]);

  useEffect(() => {
    if (!addError || addError === prevAddError) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while trying to add tariff {{name}}.', {
          name: formRef.current?.values.ime ?? '',
        })
      );
    }

    onHide();
  }, [addError, onHide, prevAddError, t, toastRef]);

  const {
    data: editData,
    error: editError,
    isLoading: isEditLoading,
    reload: editReload,
  } = useAxiosHook(
    'replace/me', // !!!
    {
      skipWhen: true,
    }
  );

  const prevEditData = usePrevious(editData);
  const prevEditError = usePrevious(editError);

  useEffect(() => {
    if (!editData || editData === prevEditData) {
      return;
    }

    if (toastRef?.current) {
      successToast(
        toastRef,
        t('Success'),
        t('Tariff {{name}} has been updated successfully.', {
          name: formRef.current?.values.ime ?? '',
        })
      );
    }

    onHide();
  }, [editData, onHide, prevEditData, t, toastRef]);

  useEffect(() => {
    if (!editError || editError === prevEditError) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while trying to update tariff {{name}}.', {
          name: formRef.current?.values.ime ?? '',
        })
      );
    }

    onHide();
  }, [editError, onHide, prevEditError, t, toastRef]);

  const validationSchema = useMemo(() => getValidationSchema(t), [t]);

  const dialogFooter = isTariffLoading ? (
    <></>
  ) : (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={() => onHide()}
        className="p-button-secondary p-button-text"
      />

      <Button
        type="button"
        label={
          isEditDialog
            ? isEditLoading
              ? t('Updating...')
              : t('Update')
            : isAddLoading
            ? t('Adding...')
            : t('Add')
        }
        disabled={isTariffLoading || isAddLoading || isEditLoading}
        onClick={() => formRef.current?.handleSubmit()}
      />
    </>
  );

  const header = isEditDialog
    ? isTariffLoading
      ? t('Loading...')
      : t('Editing {{name}}', { name: tariffData?.ime ?? '' })
    : t('Add new') + ' ' + t('tariff');

  const isOnMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Dialog
      header={header}
      footer={dialogFooter}
      visible={isShown}
      resizable={false}
      maximizable={isOnMobile}
      maximized={isOnMobile}
      onHide={onHide}
      style={{ width: 580, maxWidth: '100%' }}
    >
      {/* <Formik
        innerRef={formRef}
        // initialValues={getInitialValues(
        //   isEditDialog,
        //   tariffData ?? (mockData[0] as any)
        // )} // !!!
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (isEditDialog) {
            editReload({ data: toApiData(values) });
          } else {
            addReload({
              data: toApiData(values),
            });
          }
        }}
      >
        <Form>{isTariffLoading ? <DialogSpinner /> : <DialogContainer />}</Form>
      </Formik> */}
    </Dialog>
  );
}

export default AddEditDialog;
