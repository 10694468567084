import { yupResolver } from '@hookform/resolvers/yup';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  DevicesCouriers,
  SmartPOSTerminals,
} from '../../../../../types/api/smartPosTerminals';
import { getChangedValues } from '../../../../../utils/helpers/object';
import FieldWithErrors from '../../../../Forms/ReactHookForm/FieldWithErrors';
import {
  FormFields,
  getDefaultValues,
  getValidationSchema,
  smartPOSTerminalsStatuses,
} from './AddEditDialog.functions';

type Props = {
  isEditDialog: boolean;
  devicesCourier: DevicesCouriers[] | undefined;
  isLoadingDevicesCourier: boolean;
  data: SmartPOSTerminals | undefined;
  handleCreation: (data: FormFields) => void;
  handleEditing: (data: any) => void;
};

function FormContainer({
  isEditDialog,
  devicesCourier,
  isLoadingDevicesCourier,
  data,
  handleCreation,
  handleEditing,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const options = useMemo(() => smartPOSTerminalsStatuses(t), [t]);

  const defaultValues = useMemo(
    () => getDefaultValues(isEditDialog, data),
    [isEditDialog, data]
  );
  const validationSchema = useMemo(() => getValidationSchema(t), [t]);
  const devicesCourierOptions = useMemo(() => {
    return devicesCourier?.map((item) => {
      return { label: item.name, value: item.deviceId };
    });
  }, [devicesCourier]);

  const methods = useForm<FormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;
  console.log('errors ', errors);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  function handleFormSubmission(values: FormFields) {
    console.log('handleFormSubmission values ', values);
    if (isEditDialog) {
      handleEditing(getChangedValues(values, defaultValues));
      return;
    }

    handleCreation(values);
  }

  return (
    <FormProvider {...methods}>
      <form
        id="smart-pos-terminals-create-edit-form"
        onSubmit={handleSubmit(handleFormSubmission)}
      >
        <div className="p-fluid">
          <FieldWithErrors name="username" label={t('Name')}>
            <Controller
              name="username"
              render={({ field }) => (
                <InputText
                  id="username"
                  name="username"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="device_id" label={t('Device ID')}>
            <Controller
              name="device_id"
              render={({ field }) => (
                <Dropdown
                  id="device_id"
                  name="device_id"
                  options={devicesCourierOptions}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={isLoadingDevicesCourier}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="terminal_id" label={t('Terminal ID')}>
            <Controller
              name="terminal_id"
              render={({ field }) => (
                <InputText
                  id="terminal_id"
                  name="terminal_id"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="activation_code" label={t('Activation Code')}>
            <Controller
              name="activation_code"
              render={({ field }) => (
                <InputText
                  id="activation_code"
                  name="activation_code"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="pin" label={t('Pin')}>
            <Controller
              name="pin"
              render={({ field }) => (
                <InputText
                  id="pin"
                  name="pin"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>

          <FieldWithErrors name="status_id" label={t('Status')}>
            <Controller
              name="status_id"
              render={({ field }) => (
                <Dropdown
                  id="status_id"
                  name="status_id"
                  options={options}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldWithErrors>
        </div>
      </form>
    </FormProvider>
  );
}

export default FormContainer;
