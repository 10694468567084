import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ToastContext from '../../../../../context/ToastContext';
import useAxiosHook from '../../../../../hooks/useAxiosHook';
import usePrevious from '../../../../../hooks/usePrevious';
import { EntityIdRouteParams } from '../../../../../types/routing';
import {
  errorToast,
  successToast,
} from '../../../../../utils/helpers/primereact';

type Props = {
  isShown: boolean;
  onHide: () => void;
};

function DeleteDialog({ isShown, onHide }: Props): JSX.Element {
  const { t } = useTranslation();

  const { id } = useParams<EntityIdRouteParams>();

  const { toastRef } = useContext(ToastContext);

  const {
    data: tariffData,
    error: tariffError,
    isLoading: isTariffLoading,
  } = useAxiosHook<any>(`/replace/me/${id}`, {
    skipWhen: !isShown || !id || true, // !!!
  });

  const prevTariffError = usePrevious(tariffError);

  useEffect(() => {
    if (!tariffError || tariffError === prevTariffError) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while reading tariff data.')
      );
    }

    onHide();
  }, [onHide, prevTariffError, tariffError, t, toastRef]);

  const {
    data: deleteData,
    error: deleteError,
    isLoading: isDeleteLoading,
    reload: deleteReload,
  } = useAxiosHook(
    {
      url: `/replace/me/${0}`, // !!!
      method: 'DELETE',
    },
    {
      skipWhen: true,
    }
  );

  const prevDeleteData = usePrevious(deleteData);
  const prevDeleteError = usePrevious(deleteError);

  useEffect(() => {
    if (!deleteData || deleteData === prevDeleteData) {
      return;
    }

    if (toastRef?.current) {
      successToast(
        toastRef,
        t('Success'),
        t('Tariff {{name}} was deleted successfully.', {
          name: tariffData?.name ?? '',
        })
      );
    }

    onHide();
  }, [deleteData, onHide, prevDeleteData, tariffData?.name, t, toastRef]);

  useEffect(() => {
    if (!deleteError || deleteError === prevDeleteError) {
      return;
    }

    if (toastRef?.current) {
      errorToast(
        toastRef,
        t('Error'),
        t('An error occured while trying to delete tariff {{name}}.', {
          name: tariffData?.name ?? '',
        })
      );
    }

    onHide();
  }, [deleteError, onHide, prevDeleteError, tariffData?.name, t, toastRef]);

  const dialogFooter = isTariffLoading ? (
    <></>
  ) : (
    <>
      <Button
        type="button"
        label={t('Close')}
        onClick={() => onHide()}
        className="p-button-secondary p-button-text"
      />

      <Button
        type="button"
        label={
          isTariffLoading
            ? t('Loading...')
            : isDeleteLoading
            ? t('Deleting...')
            : t('Delete')
        }
        disabled={isTariffLoading || isDeleteLoading}
        onClick={() => deleteReload()}
        className="p-button-danger"
      />
    </>
  );

  return (
    <Dialog
      header={
        isTariffLoading
          ? t('Loading...')
          : t('Deleting {{name}}', { name: tariffData?.ime ?? '' })
      }
      footer={dialogFooter}
      visible={isShown}
      resizable={false}
      onHide={onHide}
      style={{ width: 480, maxWidth: '100%' }}
    >
      <p className="p-m-0">
        {t('Are you sure you want to delete tariff {{name}}?', {
          name: tariffData?.ime ?? '',
        })}
      </p>

      <p className="p-m-0 p-mt-2">{t('This action cannot be undone.')}</p>
    </Dialog>
  );
}

export default DeleteDialog;
