// DBTable "adresnica_status"
export enum OrderStatus {
  CreatedAddress = 10,
  CreatedAddressFromCounter = 11,
  Created = 15,
  CreatedAddressFromMobile = 16,
  CreatedGroupOrder = 20,
  AssignedToAReceptionCourier = 30,
  UnsuccessfulPickup = 33,
  Cancelled = 34,
  PickedUp = 35,
  Sorting = 40,
  AwaitingTransfer = 41,
  AwaitingControl = 45,
  Lost = 46,
  AwaitingPickupFromClient = 47,
  Abandoned = 48,
  HandedOverToARelocationCourier = 50,
  HandedOverToADeliveryCourier = 51,
  UnsuccessfulDeliveryAttempt = 55,
  MarkedForReturn = 60,
  Returned = 65,
  MarkedAsDelivered = 75,
  Delivered = 80,
}

// DBTable "failed_shipment_reason"
export enum FailedOrderReason {
  Unknown,
  DoesntWantToReceive,
  WrongAddress,
  WrongPhone,
  ICouldntGetInTime,
  DoesntAnswer,
  NobodyAnswers,
  Later,
  ReturnedOrderWithNewAddressDocument,
  DeliveryOnSpecificDate,
  MissingReconciliationDocument,
  MovedOut,
  PassedAway,
  LegalEntityDoesntExist,
  WrongRegion,
  Misassigned,
}

// DBTable "proverka_vratena_pratka"
export enum ReturnedOrderCheck {
  No = 1,
  Yes = 2,
}

export enum ParcelSeverity {
  SUCCESS = 1,
  WARNING = 2,
  ERROR = 3,
}

// DBTable "warehouse_receive_status"
// DBTable "courier_assign_status"
// DBTable "transfer_receive_status"
// DBTable "transfer_assign_status"
export enum WarehouseAssignmentAndReceptionStatusSeverity {
  Info = 1,
  Warning,
  Error,
}

// DBTable "warehouse_receive_status"
export enum WarehouseOrderReceptionStatus {
  SuccessfullyReceived = 1,
  AlreadyReceivedPreviouslyAssignedForReception,
  AlreadyReceivedFromAnotherCourier,
  AlreadyReceivedInAnotherWarehouse,
  AlreadyReceivedOnAnotherDate,
  AlreadyReceivedAssignedForDelivery,
  AlreadyReceivedAssignedForDeliveryToAnotherCourier,
  AlreadyReceivedFromDeliveryInAnotherWarehouse,
  EmptyOrder,
  NotAssignedToCourierForReception,
  AssignedForReceptionToAnotherCourier,
  NotAssignedForDelivery,
  AssignedForDeliveryToAnotherCourier,
  ShippedOrMarkedAsShipped,
  WrongWarehouse = 16,
  WrongRegion,
  WrongProduct,
  WorkingDayNotOpen,
  CourierMissingWorkOrder,
  AssignedForTransferAndCanOnlyBeReceivedFromATransferWarhouse,
  ClosedOrder,
}

// DBTable "courier_assign_status"
export enum WarehouseCourierOrderAssignmentStatus {
  SuccessfullyAssigned = 1,
  AlreadyAssigned,
  WrongDate,
  WrongCourier,
  WrongRegion,
  ShipmentNotInWarehouse,
  MissingDeliveryRegion,
  ShipmentStatusDoesNotAllowAssignment,
  MissingShipmentData,
  ForbiddenRegion,
  AlreadyAssignedToAnotherCourier,
  ProductTypeNotAllowedForDestinationRegion,
  InadequateProductTypeForAppVersion,
  UnregisteredShipment,
  WrongDateError,
  ClosedShipment,
}

// DBTable "transfer_receive_status"
export enum WarehouseTransferCourierOrderReceptionStatus {
  SuccessfullyReceivedFromTransferInWarehouse = 1,
  UnknownSerialNumber,
  MissingAddressData,
  MissingDeliveryRegion,
  UnknownDeliveryRegion,
  WrongDate,
  ShipmentNotInTransferList,
  ShipmentAlreadyReceivedInWarehouse,
  WrongStatus,
  ClosedShipment,
  AlternateHub,
}

// DBTable "transfer_assign_status"
export enum WarehouseTransferCourierOrderAssignmentStatus {
  SuccessfullyAssignedForTransferToWarehouse = 1,
  UnknownSerialNumber,
  MissingAddressData,
  UnknownDeliveryRegion,
  ShipmentAlreadyAssignedForTransfer,
  WrongStatus,
  WrongDate,
  ShipmentNotInWarehouse,
  ShipmentHasUnreconciledCash,
  ShipmentForDeliveryInCurrentRegion,
  WrongSourceWarehouse,
  WrongDestinationWarehouse,
  MissingOrIncorrectMappingRegionToWarehouse,
  ClosedShipment,
  AlternateHub,
}

export enum OrderPaymentService {
  Postage = 1,
  COD,
  ReturnDocument,
  Insurance,
  BuyWithCourier,
  ReceivalAcknowledgement,
}

export enum ReturnDocument {
  NoReturnDocument,
  HasReturnDocument,
  OrderIsReturnDocument,
}

export enum AssignmentType {
  Pickup = 1,
  Delivery = 2,
}

export enum OrderLocationType {
  Sender = 1,
  PickupLocker = 2,
  PickupCourier = 3,
  Hub = 4,
  Relocation = 5,
  DeliveryCourier = 6,
  DeliveryLocker = 7,
  Recipient = 8,
  Virtual = 9,
}

export enum ReplacementShipment {
  NoReplacementShipment = 0,
  HasReplacementShipment = 1,
  OrderIsRReplacementShipment = 2,
}
